import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"
import Slider from 'react-slick'
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import arrowRightXs from '../../../images/arrow-right-xs.svg'
import arrowRightMd from '../../../images/carousel-arrow-right.png'

const Section = styled.div`
    margin: 3rem auto 5rem;
    
    @media ${media.xl} {
        margin: 4rem auto 7rem;
    }
`

const PhotoSlider = styled(Slider)`
    position: relative;
    z-index: 2;
    .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
    }
    
    .slick-slide{
        padding: 0;
        transition: all 0.3s ease;
        height: auto;
        &.slick-current,
        &.slick-center {
          opacity: 1 !important;
          
        }
        opacity: 0.2;
        
        &.slick-active {
            //opacity: 0.2;
        }
        
        &.slick-cloned {
            //margin-left: -25%;
        }
        
        .img {
            //display: block;
            //max-width: 100%;
            //height: auto;
        }
    }
    
    .photo-wrapper {
        position: relative;
        
        cursor: pointer;
    }

    
    .slick-dots {
        bottom: -25px;
        z-index: 9;
        
        @media ${media.md} {
            bottom: -35px;
        } 
        
        @media ${media.lg} {
            bottom: -50px;
        } 
    }
    
    .slick-dots li, .slick-dots li button {
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            width: 20px;
            height: 20px;
        }
    }
    
    .slick-dots li button:before {
        color: ${props => props.theme.colors.turquoise2};
        opacity: 1;
        font-size: 10px;
        
        @media ${media.md} {
            font-size: 12px;
        }
        
        @media ${media.lg} {
            font-size: 20px;
        }
    }
    
    .slick-dots li.slick-active button:before {
        color: transparent;
        border: 3px solid #63DBE7;
        border-radius: 5px;
        top: 4px;
        left: 5px;
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            top: 3px;
            border-radius: 6px;
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            left: 0;
            top: -1px;
            border-radius: 10px;
            width: 20px;
            height: 20px;
        }
    }
    .slick-prev, .slick-next {
        width: 16px;
        height: 32px;
        bottom: -50px;
        top: initial;
        z-index: 10;
        
        @media ${media.md} {
            width: 50px;
            height: 50px;
            bottom: initial;
            top: 50%;
            transform: translateY(-50%);
        }
        
        &:focus {
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }
    
    .slick-next {
        right: 20px;
        
        @media ${media.md} {
            right: 50px;
        }
        
        &:before {
            content: '';
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
            @media ${media.md} {
                background: url(${arrowRightMd}) no-repeat center center;
                background-size: contain; 
            } 
        }
    }
    
    .slick-prev {
        left: 20px;
        
        @media ${media.md} {
            left: 50px;
        }
        
        &:before {
            content: '';
            transform: rotate(180deg);
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 6px;
            width: 100%;
            height: 100%;
            
            @media ${media.md} {
                background: url(${arrowRightMd}) no-repeat center center;
                background-size: contain; 
            } 
        }
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgImage: file(relativePath: { eq: "EducationPage/menu-bg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP])
                    }
                }
               allAnimatedFeatureCarouselJson {
                    edges {
                        node {
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1040, quality: 100)
                                }
                            }
                        }
                    }
                }
            }
		`}
        render={data => (
            <CarouselSection
                data={data}/>
        )}
    />
)

class CarouselSection extends Component {
    render() {
        const settings = {
            centerMode: true,
            centerPadding: '20%',
            slidesToShow: 1,
            dots: true,
            arrows: true,
            swipe: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        centerPadding: '1px',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: '1px',
                        centerMode: true,
                    }
                }
            ]
        }

        const items = this.props.data.allAnimatedFeatureCarouselJson.edges;
        const galleryItems = items.map(({node}, id) => {
            return (
                <div
                    className="photo-wrapper"
                    key={id}
                >
                    <GatsbyImage placeholder="none"
                                 image={getImage(node.image)}
                                 alt=""
                                 title=""
                                 className="img"
                    />
                </div>
            )

        })
        return (
            <Section bgImage={this.props.bgImage}>
                <PhotoSlider
                    {...settings}
                    className="PhotoGallery"
                    ref={slider => (this.carousel = slider)}
                >
                    {galleryItems}
                </PhotoSlider>
            </Section>

        );
    }
}

export default Query;