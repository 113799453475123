import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import styled from "styled-components";
import HeadingSpecial from "../../../components/shared/HeadingSpecial";
import {Container} from "reactstrap";
import CarouselSection from "../../../components/Education/AnimatedFeatureFilm/CarouselSection";
import SnowFlakes from "../../../components/shared/SnowFlakes";

const pageSlug = "animated-feature-film"
const pageTitle = "Animated Feature Film"
const categorySlug = "inspiration"
const categoryTitle = "Inspiration"

const Header = styled.div`
    position: relative;
`

const Content = styled.div`
    position: relative;
    text-align: center;
`

class AnimatedFeatureFilm extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>
                        <Container fluid={true}>
                            <div className="narrow mx-auto">
                                <p className="font--regular mb-4">After a long journey through story treatments, FROZEN
                                    was finally commissioned by Walt Disney Animation Studios in 2011. The screenplay
                                    was written by Jennifer Lee and directed by Chris Buck and Jennifer Lee.
                                    Wife-and-husband writing team Kristen Anderson-Lopez and Robert Lopez wrote the
                                    film’s seven songs. FROZEN premiered in 2013 to critical success, became the
                                    highest-grossing film released in that year and highest-grossing animated film of
                                    all time, and won the Academy® Award for Best Animated Feature and Best Original
                                    Song (“Let It Go”).</p>
                            </div>
                        </Container>
                        <CarouselSection/>
                        <Container fluid={true}>
                            <div className="narrow mx-auto">
                            <p className="font--regular mb-4">FROZEN’s creative team created an original story –
                                inspired by Hans Christian Andersen’s “The Snow Queen” – that captured the imagination
                                of audiences worldwide. The idea of centring the story around two sisters came early on.
                                Initially, however, the sisters were the story’s hero and villain. It wasn’t until
                                Kristen and Robert began writing “Let It Go” that they discovered Elsa wasn’t a villain,
                                but a woman afraid of her power and very misunderstood. From there, the story as we know
                                it took shape. FROZEN is the first Disney animated feature to have a woman director, and
                                Jennifer Lee is the first female director of a feature film that grossed more than $1
                                billion. Also, FROZEN is the first Disney animated fairy tale that does not revolve
                                around a romantic love story.</p>
                            </div>
                        </Container>
                    <div className="py-5">&nbsp;</div>
                        <MoreTopics adaptation design/>
                </Content>
                </SnowFlakes>
            </Layout>
        )
    }
}
export default AnimatedFeatureFilm